import { useRouter } from "next/router";
import { FC, useState } from "react";
import { CloseCircled } from "~/components/assets/icons/close-circled";
import { Spinner } from "~/components/assets/icons/spinner";
import Button from "~/components/common/buttons/Button";
import LinkText from "~/components/common/typography/LinkText";
import { User } from "~/components/hooks/useUser";
import PremiumGuardModal from "~/components/modal/modals/PremiumGuardModal";
import { track } from "~/lib/analytics";
import { AlertCardVersion } from "./AlertsSection";
import DashboardAlertCard from "./DashboardAlertCard";
import { useRecentAlertsQuery } from "./useRecentAlertsQuery";

interface AlertsCardProps {
  user: User;
  version: AlertCardVersion;
  state?: string;
}

const LIMITED_COUNT = 3;
const FULL_COUNT = 10;

export const AlertsCards: FC<AlertsCardProps> = ({ user, state, version }) => {
  const router = useRouter();
  const [showAlertPremiumGuard, setShowAlertPremiumGuard] =
    useState<boolean>(false);

  const { data, isError, isLoading, isFetching, hasNextPage, fetchNextPage } =
    useRecentAlertsQuery({
      limit: version === "limited" ? LIMITED_COUNT : FULL_COUNT,
      state,
      enabled: state !== undefined,
    });
  const hasAlerts = data?.pages?.[0].data
    ? data.pages[0].data.length > 0
    : false;

  if (isLoading) {
    return (
      <div className="flex items-center gap-4 rounded-md bg-lightGrey p-4">
        <Spinner className="h-5 w-5 animate-spin fill-current text-darkGreen" />
        <span>Loading recent funding alerts...</span>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex items-center gap-4 rounded-md bg-lightGrey p-4">
        <CloseCircled className="h-5 w-5 fill-current text-darkBlue" />
        <span>Oops... something went wrong getting the funding alerts</span>
      </div>
    );
  }

  if (!hasAlerts) {
    return (
      <div className="flex items-center gap-4 rounded-md bg-lightGrey p-4">
        <span>No funding alerts{state && ` for selected state`}</span>
      </div>
    );
  }

  if (!state) {
    return (
      <div className="flex items-center gap-4 rounded-md bg-lightGrey p-4">
        <span>Please select a state to see alerts</span>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col space-y-4">
        {!user.isMember && (
          <div className="flex flex-col items-center justify-center gap-4 rounded-md border border-lightGrey bg-[#F1FEF0] p-8">
            <span className="text-center text-xl">
              Funding Alerts is a Premium only feature
            </span>
            <Button
              color="petrolGreen"
              onClick={() => {
                track("Funding Alerts Premium Only Banner Clicked");
                router.push("/pricing");
              }}
            >
              Get Premium
            </Button>
          </div>
        )}
        <div className={`flex flex-col flex-wrap gap-4 lg:flex-row`}>
          {data?.pages.map((page, n) =>
            page.data?.map((alert, n) => (
              <DashboardAlertCard
                key={alert.id}
                userIsPremium={user.isMember}
                selectedStateCode={state}
                onClickOverride={
                  user.isMember
                    ? undefined
                    : () => setShowAlertPremiumGuard(true)
                }
                alert={alert}
              />
            ))
          )}
        </div>

        {version === "limited" &&
          data?.pages?.[0].meta?.totalCount &&
          data?.pages?.[0].meta?.totalCount > LIMITED_COUNT && (
            <div className="self-end">
              <LinkText href={`/programs/alerts?state=${state ?? null}`}>
                View More
              </LinkText>
            </div>
          )}
        {version === "full" && (
          <>
            {isFetching && (
              <div className="flex w-full items-center justify-center text-petrolGreen">
                <Spinner className="mr-2 h-4 w-4 animate-spin fill-current " />
                Loading...
              </div>
            )}
            {data && !isFetching && hasNextPage && (
              <div className="flex w-full justify-center">
                <Button color="petrolGreen" onClick={() => fetchNextPage()}>
                  Load More
                </Button>
              </div>
            )}
          </>
        )}
      </div>
      {showAlertPremiumGuard && (
        <PremiumGuardModal
          onFreeTierSelect={() => setShowAlertPremiumGuard(false)}
        />
      )}
    </>
  );
};
