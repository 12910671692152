import { FC } from "react";
import { STATES_AND_TERRITORIES } from "~/lib/data/states";
import { getSelectChoiceById } from "~/lib/utils/utils";
import { StateChip } from "./StateChips";

export const ProgramStateChips: FC<{
  stateCalloutCode?: string;
  allStateCodes: string[];
}> = ({ stateCalloutCode: stateCallout, allStateCodes }) => {
  if (stateCallout) {
    const text =
      getSelectChoiceById<string>(stateCallout, STATES_AND_TERRITORIES)?.text ??
      stateCallout;

    if (allStateCodes.length === 1) {
      return <StateChip text={text} />;
    }

    return (
      <div className="flex flex-col gap-2 sm:flex-row">
        <StateChip text={text} />
        <StateChip text={`+ ${allStateCodes.length - 1} more states`} />
      </div>
    );
  }

  return <StateChip text={`${allStateCodes.length} states`} />;
};
