import { useRouter } from "next/router";
import React from "react";
import Button from "~/components/common/buttons/Button";
import LinkButton from "~/components/common/buttons/LinkButton";

interface PremiumGuardButtonsProps {
  onFreeTierSelect: () => void;
}

const PremiumGuardButtons: React.FC<PremiumGuardButtonsProps> = ({
  onFreeTierSelect,
}) => {
  const router = useRouter();
  return (
    <div className="flex flex-col justify-center space-x-0 space-y-2 sm:flex-row sm:justify-evenly sm:space-x-2 sm:space-y-0">
      <Button
        onClick={onFreeTierSelect}
        color="petrolGreen"
        variant="Secondary"
      >
        Stick with Free Tier
      </Button>
      <LinkButton color="petrolGreen" href="/pricing">
        Get FarmRaise Premium
      </LinkButton>
    </div>
  );
};

export default PremiumGuardButtons;
