import cntl from "cntl";
import Link from "next/link";
import React from "react";

interface LinkTextProps {
  href: string;
  children?: React.ReactNode;
  textColor?: string;
}

const LinkText: React.FC<LinkTextProps> = ({
  href,
  children,
  textColor = "text-darkGreen",
}) => {
  const className = cntl`
    ${textColor}
    underline
    sm:flex-row
    cursor-pointer
  `;

  return (
    <Link href={href}>
      <span className={className}>{children}</span>
    </Link>
  );
};

export default LinkText;
