import { BellIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/router";
import { FC, useMemo } from "react";
import NumberFormat from "react-number-format";
import { ProgramStateChips } from "~/components/programs/details/state-chips/ProgramStateChips";
import { track } from "~/lib/analytics";
import { getUsDateString } from "~/lib/utils/utils";
import { Alert, AlertTypeToString } from "./useRecentAlertsQuery";

interface DashboardAlertCardProps {
  alert: Alert;
  userIsPremium: boolean;
  selectedStateCode?: string;
  onClickOverride?: () => void;
}

const DashboardAlertCard: FC<DashboardAlertCardProps> = ({
  alert,
  userIsPremium,
  selectedStateCode,
  onClickOverride,
}) => {
  const router = useRouter();
  const date = useMemo(
    () => getUsDateString(new Date(`${alert.alertDate} `)),
    [alert.alertDate]
  );

  return (
    <div className="flex w-full flex-col gap-3 rounded-md border border-lightGrey bg-white px-4 py-4 lg:w-96">
      <div className="flex h-full gap-4">
        <BellIcon className="h-6 w-6 stroke-petrolGreen" />
        <div className="flex h-full flex-col space-y-6">
          <div className="space-y-2">
            <div className="grow whitespace-nowrap text-base font-semibold">
              {AlertTypeToString[alert.type]}
            </div>
            <div>
              <ProgramStateChips
                stateCalloutCode={selectedStateCode}
                allStateCodes={alert.programInfo.states}
              />
            </div>
          </div>

          <div className="flex grow flex-col space-y-2">
            <span className="grow text-base font-semibold text-darkBlue">
              {alert.programInfo.name}
            </span>

            {alert.amountInDollars && (
              <div className="flex gap-4 text-sm text-darkGrey">
                <span className="font-semibold">Amount</span>
                <NumberFormat
                  className=""
                  value={alert.amountInDollars}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </div>
            )}
          </div>

          <span
            className="grow-0 cursor-pointer text-petrolGreen underline"
            onClick={() => {
              track("View Alert Button Clicked", {
                userIsPremium,
                programId: alert.programInfo.code,
                programName: alert.programInfo.name,
                alertType: alert.type,
                alertDate: alert.alertDate,
                alertEndDate: alert.alertEndDate,
              });
              if (onClickOverride) {
                onClickOverride();
              } else {
                if (
                  selectedStateCode ||
                  alert.programInfo.states.length === 1
                ) {
                  router.push(
                    `/programs/${alert.programInfo.code}?state=${
                      selectedStateCode ?? alert.programInfo.states[0]
                    }`
                  );
                } else {
                  router.push(`/programs/${alert.programInfo.code}`);
                }
              }
            }}
          >
            Go to program
          </span>
        </div>
      </div>
    </div>
  );
};

export default DashboardAlertCard;
