import { Dialog } from "@headlessui/react";
import React from "react";
import { CloseCircled } from "~/components/assets/icons/close-circled";

export interface ModalContainerProps {
  show?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
}

export type ModalProps = Pick<ModalContainerProps, "onClose" | "show">;

const ModalContainer: React.FC<ModalContainerProps> = ({
  children,
  onClose,
  show = true,
}) => {
  return (
    <Dialog
      open={show}
      onClose={onClose ?? (() => undefined)}
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
        <Dialog.Overlay className="fixed inset-0 bg-darkBlue bg-opacity-40 transition-opacity" />
        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div className="inline-block w-10/12 transform overflow-hidden rounded-lg bg-white p-8 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6 sm:align-middle">
          {onClose && (
            <div className="absolute right-0 top-0 hidden pr-6 pt-4 sm:block">
              <button
                type="button"
                className="rounded-full bg-white text-darkGrey focus:outline-none focus:ring-2 focus:ring-rolloverGreen focus:ring-offset-2"
                onClick={onClose}
              >
                <span className="sr-only">Close</span>
                <CloseCircled
                  className="h-6 w-6 fill-current text-darkGrey"
                  aria-hidden="true"
                />
              </button>
            </div>
          )}

          {children}
        </div>
      </div>
    </Dialog>
  );
};

export default ModalContainer;
