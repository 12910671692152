import axios, { AxiosError } from "axios";
import { useInfiniteQuery } from "react-query";
import { DataListResponse } from "~/lib/core/DataListResponse";

export type AlertType =
  | "NEW_PROGRAM_ANNOUNCEMENT"
  | "DEADLINE_REMINDER"
  | "DEADLINE_ANNOUNCEMENT"
  | "PROGRAM_INFORMATION_CHANGES";

export const AlertTypeToString: Record<AlertType, string> = {
  NEW_PROGRAM_ANNOUNCEMENT: "New Program Announcement",
  DEADLINE_REMINDER: "Deadline Reminder",
  DEADLINE_ANNOUNCEMENT: "Deadline Announcement",
  PROGRAM_INFORMATION_CHANGES: "Program Information Changes",
};

export interface Alert {
  id: string;
  message: string;
  type: AlertType;
  amountInDollars?: number | null;
  alertDate: string;
  alertEndDate: string;
  updatedAt: string;
  createdAt: string;
  programInfo: AlertProgramInfo;
}

export interface AlertProgramInfo {
  code: string;
  name: string;
  states: string[];
}

type GetAlertsResponse = DataListResponse<Alert>;

export function useRecentAlertsQuery({
  enabled = true,
  ...props
}: {
  limit: Number;
  state?: string;
  programCode?: string;
  page?: number;
  enabled?: boolean;
}) {
  return useInfiniteQuery<GetAlertsResponse, AxiosError, GetAlertsResponse>(
    ["alerts", "recent", ...Object.values(props)],
    async ({ pageParam: page = 1 }) => {
      const response = await axios.get<GetAlertsResponse>(
        "/api/proxy/v3/programs/alerts",
        {
          params: {
            ...props,
            page,
            sortBy: "alert_date",
          },
        }
      );

      return response.data;
    },
    {
      getNextPageParam(lastPage) {
        return lastPage.pagination?.next;
      },
      enabled,
    }
  );
}
