import { CheckIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/router";
import React from "react";
import { StarBadge } from "~/components/assets/icons/star-badge";
import WithLeftIcon from "~/components/common/WithLeftIcon";
import ModalContainer, { ModalContainerProps } from "../ModalContainer";
import PremiumGuardButtons from "./PremiumGuardButtons";

type PremiumGuardModalProps = ModalContainerProps & {
  onFreeTierSelect?: () => void;
};

export const check = (
  <CheckIcon className="mr-4 mt-0.5 h-5 w-5 text-petrolGreen" />
);

export const CheckLineItem: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  return (
    <div className="text-base">
      <WithLeftIcon alignItemsCenter={false} icon={check}>
        {props.children}
      </WithLeftIcon>
    </div>
  );
};

const PremiumGuardModal: React.FC<PremiumGuardModalProps> = (props) => {
  const router = useRouter();
  return (
    <ModalContainer {...props}>
      <div className="ml-4 mr-4 space-y-6">
        <div className="flex w-full justify-center">
          <StarBadge className="h-12 w-12 fill-petrolGreen" />
        </div>
        <h2 className="text-center">
          A FarmRaise Premium Subscription is required to access this feature
        </h2>
        <div className="space-y-2 rounded bg-lighterGreen p-4 text-darkGreen">
          <div className="mb-4 font-bold">Premium Members can</div>
          <CheckLineItem>Explore & save hundreds of grants</CheckLineItem>
          <CheckLineItem>Access our weekly farm newsletter</CheckLineItem>
          <CheckLineItem>Easily track their farm expenses</CheckLineItem>
          <CheckLineItem>Get alerts on new grants</CheckLineItem>
          <CheckLineItem>Generate FSA records application</CheckLineItem>
          <CheckLineItem>Generate USDA Cost Share application</CheckLineItem>
        </div>
        <PremiumGuardButtons
          onFreeTierSelect={props.onFreeTierSelect || (() => router.push("/"))}
        />
      </div>
    </ModalContainer>
  );
};

export default PremiumGuardModal;
